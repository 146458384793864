import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import { useQueryParam, StringParam } from "use-query-params";

import {
  Box,
  Button,
  Container,
  Flex,
  Grid,
  Icon,
  Text,
  chevronLeft,
  chevronUp,
} from "../../components";

import {
  Details,
  Layout,
  NavTile,
  Player,
  PlayerContextProvider,
  SongTile,
} from "../../patterns";

import { selectCollection } from "../../selectors";

const CollectionTemplate = ({ pageContext }) => {
  const [nowPlaying, setNowPlaying] = useState([]);
  const [currentSong] = useQueryParam("song", StringParam);

  const { heading, subHeading, categories, related } =
    selectCollection(pageContext);

  useEffect(() => {
    window &&
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });

    if (currentSong) {
      categories.forEach(({ items }) => {
        items.forEach((item) => {
          if (item.slug === currentSong) {
            setNowPlaying(item);
            document.getElementById(item.slug).scrollIntoView({
              behavior: "smooth",
              block: "center",
            });
          }
        });
      });
    }
  }, []);

  const handlePlayerClose = () => {
    setNowPlaying({});
  };

  return (
    <PlayerContextProvider value={{ nowPlaying }}>
      <Layout title={heading}>
        <Container $paddingX={5}>
          <Flex $flexDirection="column" $gap={5}>
            <Text
              as="h1"
              $fontSize="heading2"
              $fontFamily="heading"
              $fontWeight="900"
              $maxWidth="800px"
              $textTransform="uppercase"
            >
              {heading}
            </Text>

            <Text as="p" $maxWidth="560px">
              {subHeading}
            </Text>

            <Text as="p" $fontSize="small" $marginBottom={2} $maxWidth="560px">
              The following titles are a small selection of the library. To
              discuss specific requirements or bespoke music please email:
            </Text>

            <Text
              as="a"
              $fontSize="small"
              $fontWeight="bold"
              $marginBottom={5}
              href="mailto:info@greenwichmusic.co.uk"
            >
              info@greenwichmusic.co.uk
            </Text>

            <div>
              <Button as="a" $appearance="secondary" href="/" $marginBottom={8}>
                <Icon
                  src={chevronLeft}
                  aria-hidden
                  $marginRight={2}
                  $marginLeft={-3}
                />
                Return to Homepage
              </Button>
            </div>
          </Flex>
        </Container>

        <motion.div>
          {categories &&
            categories.map((category, i) => (
              <>
                <Container $paddingX={5}>
                  <Text
                    as="h2"
                    $fontSize="heading3"
                    $fontFamily="heading"
                    $fontWeight="900"
                    $marginBottom={5}
                    $textTransform="uppercase"
                  >
                    {category.heading}
                  </Text>
                </Container>

                <Box
                  $marginBottom={8}
                  as={motion.div}
                  $paddingY={6}
                  $bgColor="background.dark"
                >
                  <Container $paddingX={2}>
                    <Grid
                      $gap={5}
                      $gridAutoFlow="dense"
                      $gridTemplateColumns={{
                        xs: "1fr",
                        md: "1fr 1fr",
                      }}
                    >
                      {category.items.map((item, i) => (
                        <SongTile
                          key={`item-${i}`}
                          id={item.slug}
                          $selected={
                            nowPlaying &&
                            nowPlaying.catalogueNumber === item.catalogueNumber
                          }
                          $counter={i + 1}
                        >
                          <SongTile.Content>
                            <Text
                              as="p"
                              $fontSize="heading4"
                              $fontFamily="heading"
                              $fontWeight="900"
                              $textTransform="uppercase"
                              $marginBottom={2}
                            >
                              {item.heading}
                            </Text>

                            <Details
                              composer={item.composer}
                              publisher={item.publisher}
                              catalogueNumber={item.catalogueNumber}
                              $marginBottom={4}
                            />
                          </SongTile.Content>

                          <SongTile.Play
                            onClick={() => setNowPlaying(item)}
                            title={`Play ${item.heading}`}
                          />

                          <SongTile.Purchase
                            as="a"
                            href={`/tracks/${item.catalogueNumber}`}
                          >
                            Purchase
                          </SongTile.Purchase>
                        </SongTile>
                      ))}
                    </Grid>
                  </Container>
                </Box>
              </>
            ))}
        </motion.div>

        <Container $size="md" $paddingX={5}>
          <Box $textAlign="center">
            <Button
              $appearance="secondary"
              onClick={() => window.scrollTo(0, 0)}
              $marginBottom={7}
            >
              <Icon
                src={chevronUp}
                aria-hidden
                $marginRight={2}
                $marginLeft={-3}
              />
              Back to top
            </Button>
          </Box>

          <Text
            as="h2"
            $fontSize="heading2"
            $fontFamily="heading"
            $fontWeight="900"
            $marginBottom={6}
            $textAlign="center"
            $textTransform="uppercase"
          >
            Browse Collections
          </Text>

          <Grid $gap={6} $gridTemplateColumns={{ xs: "1fr", lg: "1fr 1fr" }}>
            {related.map((relatedCollection, i) => (
              <NavTile
                as="a"
                heading={relatedCollection.heading}
                subHeading={relatedCollection.subHeading}
                href={`/collections/${relatedCollection.slug}`}
                $counter={relatedCollection.order}
              />
            ))}
          </Grid>
        </Container>

        <Player {...nowPlaying} handleClose={handlePlayerClose} />
      </Layout>
    </PlayerContextProvider>
  );
};

export default CollectionTemplate;
